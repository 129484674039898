<template>

  <div v-if="data && data.role_name && data.role_name!='other_b2b'" class="accounts_content">

      <div v-if="data.role_name !== 'manager'" class="create_container">

        <div class="input_item">
          <client-only>
          <n-select
            v-model:value="selected"
            filterable
            :placeholder="$t('brand_manager_view_accounts_type_username')"
            :options="data_search"
            :loading="isFetching"
            clearable
            remote
            @search="searchTesters"
            value-field="id"
            label-field="name"
          />
        </client-only>
        </div>

        <div class="button_item">
          <UiButton
            @click="addTester()"
            :name="$t('brand_manager_view_testers_add_tester_button')"
            />
        </div>

      </div>

      <div style="clear:both">&nbsp;</div>

      <n-table :single-column="false" :single-line="true" class="accounts_table">
      
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
  
        <tr v-for="item in data_testers">

          <td class="nm">
             <a class="item user_ava" :href="'/grower/' + item.name">
               <img class="avatar_user ui avatar image" :src="item.avatar_little" alt="" data-not-lazy>
             </a>
             <a :href="'/grower/' + item.name">
               {{ item.name }}
             </a>
          </td>

          <td class="rl">
            <UiButton
              :ico="'icon-remove'"
              :type="'red'"
              @click="removeTester(item.u_id)"
              />
          </td>

        </tr>
      
      </n-table>

    </div>
  
</template>

<script setup>

import { NButton, NSelect, NTable, useMessage, NMessageProvider } from 'naive-ui'
import { useI18n } from "vue-i18n"
import debounce from 'lodash/debounce'

const {$api} = useNuxtApp()
const { t } = useI18n()
const message = useMessage()

const isFetching = ref(false)
const selected = ref(null)
const data_search = ref([])

const props = defineProps({
  data: {
    type: Object    
  }
});


const bank_info = ref(props.data.bank_info);

const searchTesters = (query) => {
  if(!query.length || isFetching.value){
      data_search.value = []
      return []
  }
  isFetching.value = true;
  
  $api.getB2BSearchTesters(query).then(res => {
    if(res){
      data_search.value = res;
      isFetching.value = false;
      return data_search;
    }
  })
}


const addTester = () => {
  if(!selected.value){
    return
  }
  $api.postB2BAddTester({
    brand_id: bank_info.value.id,
    user_id: parseInt(selected.value)
  }).then(res => {
    loadTesters();
  }).catch(function (error) {
     message.error(t('brand_manager_view_generic_error'));
  });
}

const removeTester = (id) => {
  if(!id){
    return
  }
  $api.postB2BRemoveTester({
    brand_id: bank_info.value.id,
    remove_id: parseInt(id)
  }).then(res => {
    loadTesters();
  }).catch(function (error) {
     message.error(t('brand_manager_view_generic_error'));
  });
}



const loadTesters = async () => {
  const response = await $api.getB2BTesters();
  data_testers.value = response.items_tester
  return response.items_tester;
}

const { pending: is_loading,  data: data_testers } = await useLazyAsyncData('data_testers', async () => await loadTesters())

</script>

<style scoped>

/**/
 
</style>

<style>
 
/* accounts */

.accounts_content{

}
.accounts_content .input_item{
  width: 340px;
  margin-right: 10px;
}
.accounts_content .create_container{
    display: flex;
    align-items: center;
}

.accounts_content .button_item{
  width: 160px;
}

.accounts_content table td .user_ava img{
  width: 28px;
  height: 28px;
  margin-right: 4px;
  float: left;
  border-radius: 100%;
}
.accounts_content table td.nm a{
  vertical-align: middle;
}
.accounts_content table td.rl{
  width: 150px;
  vertical-align: middle;
  text-align: right;
}
.accounts_content table td.ac{
  width: 50px;
}


@media (max-width: 768px) {
  .accounts_content .input_item{
    width: 160px;
    margin-right: 5px;
  }
  .accounts_content table td.user_ava{
    display: none;
  }
  .accounts_content table td.rl{
    width: auto;
  }
}

</style>